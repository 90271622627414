import { ISettledBet, UserBonus } from '../../global.d';
import { FragmentType, getFragmentData } from '../../gql';
import { setBetAmount, setCoinAmount, setCoinValue, setFreeRoundBonus, setSlotConfig } from '../../gql/cache';
import client from '../../gql/client';
import { betBonusRewardFragment, userBonusFragment } from '../../gql/fragment';
import { getUserBonuses } from '../../gql/query';
import { EventTypes, eventManager } from '../config';
import { BonusDataByBonusId, BonusKind } from '../config/bonusInfo';

export const isGetFreeSpinBonus = (nextResult: ISettledBet) => {
  type RewardType = NonNullable<NonNullable<ISettledBet>['rewards']>[number];
  type BetBonusRewardType = FragmentType<typeof betBonusRewardFragment> & { __typename: 'BetBonusReward' };

  const isBonusReward = (reward: RewardType): reward is BetBonusRewardType => reward?.__typename === 'BetBonusReward';
  const reward = nextResult?.rewards
    ?.filter(isBonusReward)
    .map((rf) => getFragmentData(betBonusRewardFragment, rf))
    .find((r) => getFragmentData(userBonusFragment, r.userBonus)?.bonus?.type === 'FREE_SPIN');

  const freeSpinBonus = getFragmentData(userBonusFragment, reward?.userBonus);

  return freeSpinBonus ? true : false;
};

export const getFreeRoundBonus = (nextResult: ISettledBet) => {
  const isFreeSpin = nextResult?.bet.data.bonuses.find(
    (e) => BonusDataByBonusId[e.bonusId]?.bonusKind === BonusKind.FREE_SPINS,
  )
    ? true
    : false;

  const bonuses = nextResult?.bet.data.bonuses.find((e) => e.isFreeBet);
  return isFreeSpin ? undefined : bonuses;
};

export const getUserActiveBonuses = async () => {
  return client.query<{ userBonuses: UserBonus[] }>({
    query: getUserBonuses,
    variables: {
      input: { status: 'ACTIVE', slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });
};

export const updateCoinValueFromFreeRoundBonus = () => {
  setCoinValue(setFreeRoundBonus().coinValue!);
  setCoinAmount(setFreeRoundBonus().coinAmount!);
  setBetAmount(setFreeRoundBonus().coinAmount! * setSlotConfig().lineSet.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};
