import { IPointData } from 'pixi.js';

import {
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
} from '../config';

/*
const test = {
  a: {
    key: 'title',
    type: 'AutoResizeText',
    position: { x: 0, y: 0 },
    anchor: { x: 0, y: 0 },
    i18n:
    style:
  },
};
*/
export const FEATURE_BTN_DEFAULT_SCALE = 0.95;
export const FEATURE_BTN_MOUSE_OVER_SCALE = 1.13 * FEATURE_BTN_DEFAULT_SCALE;
export const FEATURE_BTN_MOUSE_CLICK_SCALE = 0.9 * FEATURE_BTN_DEFAULT_SCALE;

export const FEATURE_BTN_LANDSCAPE_X = -220;
export const FEATURE_BTN_LANDSCAPE_Y = 730;

export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 0;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 0;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 4;

const FEATURE_POPUP_BASE_X = 428;
const FEATURE_POPUP_BASE_Y = 84;
export const FEATURE_POPUP_SPINE_WIDTH = 1208;
export const FEATURE_POPUP_SPINE_HEIGHT = 794;
export const FEATURE_POPUP_CENTER_X = FEATURE_POPUP_SPINE_WIDTH / 2;

export const FEATURE_POPUP_POSITIONS = {
  BG: { x: -FEATURE_POPUP_SPINE_WIDTH / 2, y: -FEATURE_POPUP_SPINE_HEIGHT / 2 } as IPointData,
  TITLE: { x: FEATURE_POPUP_CENTER_X, y: 135 },
  BET_TEXT: { x: FEATURE_POPUP_CENTER_X, y: 226 },
  BET_VALUE: { x: FEATURE_POPUP_CENTER_X, y: 343 },
  TOTAL_COST_TITLE: { x: FEATURE_POPUP_CENTER_X, y: 394 },
  TOTAL_COST_VALUE: { x: FEATURE_POPUP_CENTER_X, y: 442 - 17 },
  MINUS_BUTTON: { x: 332 + 70 / 2, y: 302 + 88 / 2 },
  PLUS_BUTTON: { x: 816 + 70 / 2, y: 302 + 88 / 2 },
  CANCEL_BUTTON: { x: 358 + 142 / 2, y: 558 + 158 / 2 },
  OK_BUTTON: { x: 720 + 142 / 2, y: 558 + 158 / 2 },
};

export const FEATURE_POPUP_CONFIRM_POSITIONS = {
  BG: { x: -FEATURE_POPUP_SPINE_WIDTH / 2, y: -FEATURE_POPUP_SPINE_HEIGHT / 2 } as IPointData,
  TITLE: { x: FEATURE_POPUP_CENTER_X, y: 130 },
  TOTAL_COST_TITLE: { x: FEATURE_POPUP_CENTER_X, y: 367 },
  TOTAL_COST_VALUE: { x: FEATURE_POPUP_CENTER_X, y: 442 - 17 },
  CANCEL_BUTTON: { x: 358 + 142 / 2, y: 558 + 158 / 2 },
  OK_BUTTON: { x: 720 + 142 / 2, y: 558 + 158 / 2 },
};
export const FEATURE_POPUP_MP_BTN_HITAREA_SCALE = 1.8;

export const FEATURE_POPUP_LANDSCAPE_POSITION_X = FEATURE_POPUP_BASE_X + SAFE_AREA_LANDSCAPE_PIVOT_X;
export const FEATURE_POPUP_LANDSCAPE_POSITION_Y = FEATURE_POPUP_BASE_Y + SAFE_AREA_LANDSCAPE_PIVOT_Y;
export const FEATURE_POPUP_PORTRAIT_POSITION_X = 1 + SAFE_AREA_PORTRAIT_PIVOT_X;
export const FEATURE_POPUP_PORTRAIT_POSITION_Y = 596 + SAFE_AREA_PORTRAIT_PIVOT_Y;
