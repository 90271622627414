import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useMemo } from 'react';

import {
  setIsAllowForceReelStop,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeRoundBonus,
  setIsFreeSpinsWin,
  setIsGetUserBonuses,
  setIsInTransition,
  setIsOpenedLevelUpBanner,
  setIsOpenedMessageBanner,
  setIsOpeningScenePlaying,
  setIsPopupFreeRoundsOpened,
  setIsReSpinsWin,
  setIsSnowCorrect,
  setIsSpinShortCut,
} from '../../gql/cache';
import { configGql, getAutoSpinsGql, getBetAmountGql } from '../../gql/query';
import { EventTypes, eventManager } from '../../slotMachine/config';

const EventListener: React.FC = () => {
  const { data: configData } = useQuery(configGql);
  const { isTurboSpin, isSoundOn, isSpinInProgress, isSlotBusy, isSlotStopped } = configData!;

  const { data: betData } = useQuery(getBetAmountGql);
  const { betAmount } = betData!;

  const { data: autoSpins } = useQuery(getAutoSpinsGql);
  const { isAutoSpins, autoSpinsLeft } = autoSpins!;

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_AUTO_SPINS, isAutoSpins, setIsContinueAutoSpinsAfterFeature());
  }, [isAutoSpins]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_AUTO_SPINS_LEFT, autoSpinsLeft);
  }, [autoSpinsLeft]);

  useMemo(() => {
    eventManager.emit(EventTypes.TOGGLE_TURBO_SPIN, isTurboSpin);
  }, [isTurboSpin]);

  useMemo(() => {
    eventManager.emit(EventTypes.TOGGLE_SOUND, isSoundOn);
  }, [isSoundOn]);

  useMemo(() => {
    eventManager.emit(EventTypes.UPDATE_BET, betAmount);
  }, [betAmount]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SPIN_IN_PROGRESS, isSpinInProgress);
  }, [isSpinInProgress]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SLOT_IS_BUSY, isSlotBusy);
  }, [isSlotBusy]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SLOTS_STOPPED, isSlotStopped);
  }, [isSlotStopped]);

  const isFreeSpinsWin = useReactiveVar(setIsFreeSpinsWin);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_FREESPINS_WIN, isFreeSpinsWin);
  }, [isFreeSpinsWin]);

  const isRespinWin = useReactiveVar(setIsReSpinsWin);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_RESPINS_WIN, isRespinWin);
  }, [isRespinWin]);

  const isOpenedMessageBanner = useReactiveVar(setIsOpenedMessageBanner);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_OPENED_MESSAGE_BANNER, isOpenedMessageBanner);
  }, [isOpenedMessageBanner]);

  const isInTransition = useReactiveVar(setIsInTransition);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_IN_TRANSITION, isInTransition);
  }, [isInTransition]);

  const isOpeningScenePlaying = useReactiveVar(setIsOpeningScenePlaying);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_OPENING_SCENE_PLAYING, isOpeningScenePlaying);
  }, [isOpeningScenePlaying]);

  const isSpinShortCut = useReactiveVar(setIsSpinShortCut);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SPIN_SHORTCUT_ON, isSpinShortCut);
  }, [isSpinShortCut]);

  const isAllowForceReelStop = useReactiveVar(setIsAllowForceReelStop);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_ALLOW_FORCE_REEL_STOP, isAllowForceReelStop);
  }, [isAllowForceReelStop]);

  const isOpenedLevelUpBanner = useReactiveVar(setIsOpenedLevelUpBanner);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_OPENED_LEVEL_UP_BANNER, isOpenedLevelUpBanner);
  }, [isOpenedLevelUpBanner]);

  const isSnowCorrect = useReactiveVar(setIsSnowCorrect);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SNOW_CORRECT, isSnowCorrect);
  }, [isSnowCorrect]);

  const isFreeRoundBonus = useReactiveVar(setIsFreeRoundBonus);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_FREE_ROUND_BONUS, isFreeRoundBonus);
  }, [isFreeRoundBonus]);

  const isPopupFreeRoundsOpened = useReactiveVar(setIsPopupFreeRoundsOpened);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED, isPopupFreeRoundsOpened);
  }, [isPopupFreeRoundsOpened]);

  const isGetUserBonuses = useReactiveVar(setIsGetUserBonuses);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_GET_USER_BONUSES, isGetUserBonuses);
  }, [isGetUserBonuses]);

  return null;
};

export default EventListener;
