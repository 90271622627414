import { Container } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { goToLobby, lobbyBtnAvailable } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { GameMode, PopupOpeningTypes } from '../../consts';
import {
  setBrokenGame,
  setCurrentBonus,
  setIsBuyFeaturePopupOpened,
  setIsContinueAutoSpinsAfterFeature,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsPopupOpeningInProgress,
  setIsSoundOn,
} from '../../gql/cache';
import { calcPercentage, getGameModeByBonusId, isFreeSpinsMode, queryParams } from '../../utils';
import Tween from '../animations/tween';
import { Toast } from '../components/ui/toast';
import { UiButton } from '../components/ui/uiButton';
import { EventTypes, eventManager } from '../config';

import { SoundBtn } from './soundBtn';

class MenuBtn extends UiButton {
  private gameMode: GameMode;

  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  private isFreeSpinsWin = false;

  private isPopupFreeRoundsOpened = false;

  private soundBtn = new SoundBtn();

  private lobbyBtn = new UiButton('lobby');

  private historyBtn = new UiButton('history');

  private menuContainer = new Container();

  private isOpened = false;

  private soundToast = new Toast();

  private isReSpinsWin = false;
  constructor() {
    super('menu');
    this.gameMode = setBrokenGame() ? getGameModeByBonusId(setCurrentBonus().bonusId) : GameMode.REGULAR;
    this.interactive = !this.isDisabled;
    this.btn.on('click', () => this.toggleMenu());
    this.btn.on('touchstart', () => this.toggleMenu());
    this.initSubscriptions();
    this.handleDisable();
    this.initMenu();
    this.addChild(this.soundToast);

    if (AudioApi.isRestricted) {
      this.soundIsFreezed();
    }
  }

  private soundIsFreezed(): void {
    this.soundToast.setIsLoading();
    this.setDisable(true);
  }

  private initSubscriptions(): void {
    eventManager.on(EventTypes.CHANGE_MODE, (settings: { mode: GameMode }) => {
      this.gameMode = settings.mode;
      this.handleDisable();
    });
    AudioApi.on('interrupted', (isInterrupted) => {
      if (isInterrupted) {
        AudioApi.unSuspend();
        this.soundToast.setIsOff();
      } else {
        this.soundToast.setIsOnOff(setIsSoundOn());
      }
    });
    AudioApi.on('suspended', (isSuspended) => {
      if (isSuspended) {
        AudioApi.unSuspend();
        this.soundToast.setIsOff();
      } else {
        this.soundToast.setIsOnOff(setIsSoundOn());
        AudioApi.unSuspend();
        AudioApi.processRestriction({
          restricted: false,
        });
        AudioApi.setSoundState(setIsSoundOn());
      }
    });

    eventManager.on(EventTypes.SOUND_INITIALIZED, () => {
      this.soundToast.setIsOnOff(setIsSoundOn());
      this.handleCloseMenu();
      this.handleDisable();
    });

    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean, isContinueAutoSpinsAfterFeature: boolean) => {
      this.isAutoSpins = isAutoSpins || isContinueAutoSpinsAfterFeature;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleCloseMenu();
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleCloseMenu();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED, (isPopupFreeRoundsOpened: boolean) => {
      this.isPopupFreeRoundsOpened = isPopupFreeRoundsOpened;
      this.handleDisable();
    });

    eventManager.on(EventTypes.SET_IS_RESPINS_WIN, (isReSpinWin: boolean) => {
      this.isReSpinsWin = isReSpinWin;
      this.handleDisable();
    });
  }

  private toggleMenu(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE) {
      if (!this.isOpened) {
        AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
        this.isOpened = true;
      } else {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
        this.isOpened = false;
      }

      this.updateIntent(this.isOpened ? 'close' : 'menu');
      this.menuContainer.visible = this.isOpened;
      this.soundToast.visible = false;
    }
  }

  private handleCloseMenu(): void {
    this.isOpened = false;
    this.updateIntent('menu');
    this.menuContainer.visible = this.isOpened;
    this.soundToast.visible = false;
  }

  private handleDisable = (): void => {
    this.setDisable(
      isFreeSpinsMode(this.gameMode) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        this.isFreeSpinsWin ||
        this.isReSpinsWin ||
        this.isPopupFreeRoundsOpened ||
        setIsBuyFeaturePopupOpened() ||
        setIsContinueAutoSpinsAfterFeature() ||
        setCurrentBonus().isActive,
    );
  };

  private initMenu(): void {
    this.menuContainer.visible = false;
    this.lobbyBtn.visible = lobbyBtnAvailable(queryParams);
    this.lobbyBtn.interactive = true;
    this.lobbyBtn.on('click', () => goToLobby(queryParams));
    this.lobbyBtn.on('touchstart', () => goToLobby(queryParams));
    this.historyBtn.interactive = true;
    this.historyBtn.on('click', () => this.handleOpenHistory());
    this.historyBtn.on('touchstart', () => this.handleOpenHistory());
    this.menuContainer.addChild(this.soundBtn, this.historyBtn, this.lobbyBtn);
    this.addChild(this.menuContainer);
  }

  private handleOpenHistory(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.HISTORY_POPUP);

      setIsOpenHistoryPopup(!setIsOpenHistoryPopup());
      setIsOpenInfoPopup(false);
      setIsOpenAutoplayPopup(false);
      setIsOpenBetSettingsPopup(false);
      setIsBuyFeaturePopupOpened(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      Tween.setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  public override handlePosition(): void {
    this.btn.anchor.set(0, 0);
    let size = calcPercentage(this.applicationSize.width, 7.5);
    const gap = calcPercentage(this.applicationSize.width, 1.25);
    let x = gap;
    let y = gap;

    this.setSize(calcPercentage(this.applicationSize.width, 7.5));

    this.soundBtn.btn.anchor.set(0, 0);
    this.soundBtn.setSize(size);
    this.soundBtn.y = 0;
    this.soundBtn.x = size;

    this.historyBtn.btn.anchor.set(0, 0);
    this.historyBtn.y = 0;
    this.historyBtn.setSize(size);
    this.historyBtn.x = size * 2;

    this.lobbyBtn.btn.anchor.set(0, 0);
    this.lobbyBtn.y = 0;
    this.lobbyBtn.setSize(size);
    this.lobbyBtn.x = size * 3;

    this.soundToast.scale.set(size / 126);
    this.soundToast.x = size + gap;

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 7.5);
      this.btn.anchor.set(1, 0);
      x = this.applicationSize.width - gap;
      y = gap;
      this.setSize(size);

      this.soundBtn.btn.anchor.set(1, 0);
      this.soundBtn.setSize(size);
      this.soundBtn.y = size;
      this.soundBtn.x = 0;

      this.historyBtn.btn.anchor.set(1, 0);
      this.historyBtn.setSize(size);
      this.historyBtn.y = size * 2;
      this.historyBtn.x = 0;

      this.lobbyBtn.btn.anchor.set(1, 0);
      this.lobbyBtn.setSize(size);
      this.lobbyBtn.y = size * 3;
      this.lobbyBtn.x = 0;

      this.soundToast.scale.set(size / 126);
      this.soundToast.x = -(this.soundToast.width + size + gap);
    }

    this.x = x;
    this.y = y;
  }
}

export default MenuBtn;
